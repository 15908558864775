import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import "./contactus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
const CantactUs = () => {
  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="banner text-center pt-5 pb-5">
        <div className="container conabsolute">
          <h1>
            Contact <span>us</span>
          </h1>
          <p className="mt-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. <br />
            Lorem Ipsum has been the industry's standard
          </p>
          <img
            src="./image/arrowleft.png"
            className="conimgarrow1"
            alt="arrow"
          />
          <img
            src="./image/arrowright (2).png"
            className="conimgarrow2"
            alt="arrow"
          />
        </div>
      </div>
      {/* content2  */}
      <div className="info">
        <div className="sec1 ">
          <i className="icon mt-2">
            <FontAwesomeIcon icon={faPhone} className="styleicon me-4" />
          </i>
          <p>
            +1-940-394-2948
            <br />
            +1-389-385-3807
          </p>
        </div>

        <div className="sec2 ">
          <i className="icon mt-2">
            <FontAwesomeIcon icon={faEnvelope} className="styleicon me-4" />
          </i>
          <p>
            support@infletio.in <br />
            contact@infletio.in
          </p>
        </div>

        <div className="sec3 ">
          <i className="icon mt-2">
            <FontAwesomeIcon icon={faLocationDot} className="styleicon me-4" />
          </i>
          <p>
            34 Madison Street,
            <br />
            NY, USA 10005
          </p>
        </div>
      </div>
      {/* content3 */}

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="table">
              <div className="row">
                <div className="col-lg-6 field">
                  <form>
                    <label for="fname"> Name</label>
                    <br />
                    <div className="fieldout">
                      <input
                        type="text"
                        name="fname"
                        maxlength="30"
                        autofocus
                        placeholder="i.e. John Doe"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </form>
                </div>
                <div className=" col-lg-6 field">
                  <div className="form">
                    <label for="fname">Email</label>
                    <br />
                    <input
                      type="email"
                      name="fname"
                      placeholder="i.e. john@mail.com"
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="">
                    <label for="fname">Phone Number</label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      required
                      placeholder="i.e. +1-234-567-7890"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="">
                    <label for="fname">Subject</label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      placeholder="i.e. I need a help"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className=" col-lg-6 field gy-2">
                  <div className="correction">
                    <label for="fname">Message</label>
                    <br />
                    <input
                      type="text"
                      name="fname"
                      placeholder="i.e. I need a help"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <button className="sendbtn mt-5 ms-2">Send</button>
            </div>
          </div>
        </div>
      </div>
      <div className="contactfooter">
        <Footer />
      </div>
    </div>
  );
};
export default CantactUs;
