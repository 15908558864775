import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ft1">
                <img src="./images/logo.png" alt="logo" />
                <p className="mt-3">
                  Lorem Ipsum is simply dummy text of the
                  <br /> printing and typesetting industry. Lorem Ipsum <br />
                  has been the industry's standard Lorem
                  <br /> Ipsum is simply dummy text of the printing and
                  <br />
                  typesetting industry.
                </p>
                <span className="otherlogos">
                  <a href="#" className="anchor">
                    <img src="./image/logo-twitter.png" alt="logo" />
                  </a>
                  <a href="#" className="anchor">
                    {" "}
                    <img src="./image/logo-facebook.png" alt="logo" />
                  </a>
                  <a href="#" className="anchor">
                    {" "}
                    <img src="./image/logo-instagram.png" alt="logo" />
                  </a>
                  <a href="#" className="anchor">
                    {" "}
                    <img src="./image/logo-linkedin.png" alt="logo" />
                  </a>
                </span>
              </div>
            </div>
            {/*  */}
            <div className="col-lg-8 secondpart">
              <div className="row">
                <div className="footerdiv">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3 ">
                    <div className="secondpart1">
                      <h4>Company</h4>
                      <ul className="secondpartul">
                        <li className="mt-3 footernav">
                          <a href="#Aboutus" onClick={scrollToTop}>
                            About Us
                          </a>
                        </li>
                        <li className="mt-3 footernav">
                          <a href="#CantactUS" onClick={scrollToTop}>
                            Contact Us
                          </a>
                        </li>
                        <li className="mt-3 footernav">
                          <a href="#Blogs" onClick={scrollToTop}>
                            Blogs
                          </a>
                        </li>

                        <li className="mt-3 footernav">
                          <a href="#jobOpportunities" onClick={scrollToTop}>
                            Job Opportunities
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-lg-2">
                    <div className="secondpart2">
                      <h4>Legal</h4>
                      <ul className="secondpartul">
                        <li className="mt-3">
                          <a href="">Privacy Policy</a>
                        </li>

                        <li className="mt-3">
                          <a href="">Terms&Condition</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-lg-4">
                    <div className="secondpart3">
                      <h4>Get in touch</h4>
                      <ul className="secondpartul">
                        <li className="mt-3">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="mailfoot me-2"
                            style={{ color: "#0bb4a1" }}
                          />
                          <a href="mailto:  info@infletio.in">
                            info@infletio.in
                          </a>
                        </li>
                        <li className="mt-3">
                          <FontAwesomeIcon
                            icon={faPhoneVolume}
                            className="callfoot me-2"
                            style={{ color: "#0bb4a1" }}
                          />
                          <a href="tel:+91-40-66116611">
                            Toll No: +91-40-66116611
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <span className="lastpara">
          © 2024 Copyright, All Right Reserved, Infletio
        </span>
      </div>
    </div>
  );
};

export default Footer;
