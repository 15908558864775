import React from "react";
import Navbar from "../../Navbar";
import Homecontent from "../Containers/Homecontent";
import Footer from "../../Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Homecontent />
      <Footer />
    </div>
  );
};

export default Home;
