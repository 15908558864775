import React from "react";
import Navbar from "../../Navbar";
import "./BrowseSub.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faLocationDot,
  faMagnifyingGlass,
  faTableCellsLarge,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Footer";

const BrowseSub = () => {
  return (
    <div>
      <span>
        <Navbar />
      </span>

      <div>
        <div className="jobbanner text-center pt-5 pb-5">
          <h1>
            Find Your <span>Dream Job</span>
          </h1>
          <p className="mt-4">
            Find your next career at companies like HubSpot, Nike, and Dropbox
          </p>
          {/*  */}
          <div className="mt-4 jobsearch ">
            <div className="container">
              <div className="row justify-content-center gx-0 searchblog">
                <div className="col-lg-8 col-12 col-md-12">
                  <div className="row d-flex searchrow ">
                    <div className="col-lg-5  col-12 brdrght col-md-7">
                      <div className="jobcat">
                        <div className="">
                          <FontAwesomeIcon
                            icon={faClipboardList}
                            className="jobcategoryicon"
                          />
                        </div>
                        <div className="inp1">
                          <input
                            type="text"
                            placeholder="Category"
                            // style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-12 col-md-7 ">
                      <div className="jobloc">
                        <div className="d-flex searchrgt">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="joblocicon"
                          />

                          <div className="inp2">
                            <input type="text" placeholder="Location" />
                          </div>
                        </div>

                        <div className="jobsearch ">
                          <button className="jobsrcbtn">
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="jobsicon"
                            />
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="pagesec mt-4">
        <div className="findjobs">
          <a href="#JobOpportunities">Find Jobs</a>
        </div>
        <div className="BrowseComp ms-5">
          <a href="#BrowseCompanies">BrowseCompanies</a>
        </div>
      </div>

      <div className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      Industry
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="typeofemp">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel pt-0">
                          Advertising (43)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Business Service (4)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Blockchain (5)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Cloud (15)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Consumer Tech (5)
                        </label>
                      </div>

                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Education (34)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Fintech (45)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          Gaming (33)
                        </label>
                      </div>
                      <var>
                        {" "}
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Food & Beverage (5)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Healthcare (3)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Hostinng (5)
                          </label>
                        </div>
                        <div className="typeofemp mt-2">
                          <input type="checkbox" className="check" />
                          <label for="vehicle1" className="ps-2 checklabel">
                            Media (4)
                          </label>
                        </div>
                      </var>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item mt-4">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      Company Size
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="typeofemp">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel pt-0">
                          1-50 (25)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          51-150 (57)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          151-250 (45)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          501-1000 (43)
                        </label>
                      </div>
                      <div className="typeofemp mt-2">
                        <input type="checkbox" className="check" />
                        <label for="vehicle1" className="ps-2 checklabel">
                          1000 - above (23)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div className="righttop">
                <div className="alljob">
                  <h4>All Jobs</h4>
                  <p>Showing 73 results</p>
                </div>
                <div className="sortby ">
                  Sort By:
                  <select className="ms-3 sortdrop">
                    <option value="input"> Most Revelant Jobs</option>
                    <option value="description"> Most Search Jobs</option>
                    <option value="packing"> High Paid Jobs </option>
                  </select>
                </div>
              </div>

              <div className="">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Stripe</h5>
                      <p className="mt-2">
                        Stripe is a software platform for starting and running
                        internet businesses. Millions of businesses rely on
                        Stripe’s software tools...
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                      <p3 className="browsubbs2 ms-3">Payment Gateway</p3>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Truebill</h5>
                      <p className="mt-2">
                        Take control of your money. Truebill develops a mobile
                        app that helps consumers take control of their
                        financial...
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                    </div>
                  </div>

                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Square</h5>
                      <p className="mt-2">
                        Robinhood is lowering barriers, removing fees, and
                        providing greater access to financial information.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                      <p3 className="browsubbs2 ms-3">Blockchain</p3>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Coinbase</h5>
                      <p className="mt-2">
                        Coinbase is a digital currency wallet and platform where
                        merchants and consumers can transact with new digital
                        currencies.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                      <p3 className="browsubbs2 ms-3">Blockchain</p3>
                    </div>
                  </div>

                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Robinhood</h5>
                      <p className="mt-2">
                        Robinhood is lowering barriers, removing fees, and
                        providing greater access to financial information.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Kraken</h5>
                      <p className="mt-2">
                        Based in San Francisco, Kraken is the world’s largest
                        global bitcoin exchange in euro volume and liquidity.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                      <p3 className="browsubbs2 ms-3">Blockchain</p3>
                    </div>
                  </div>

                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Revolut</h5>
                      <p className="mt-2">
                        When Revolut was founded in 2015, we had a vision to
                        build a sustainable, digital alternative to traditional
                        big banks.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="browssubbox">
                      <div className="subboxtop">
                        <img src="./images/alljoblog1.png" alt=".." />
                        <p1>7 Jobs</p1>
                      </div>
                      <h5 className="mt-3">Divvy</h5>
                      <p className="mt-2">
                        Divvy is a secure financial platform for businesses to
                        manage payments and subscriptions.
                      </p>
                      <p2 className="browsubbs1 ">Business</p2>
                      <p3 className="browsubbs2 ms-3">Blockchain</p3>
                    </div>
                  </div>

                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center mt-5">
                      <li class="page-item">
                        <a class="page-link" href="#">
                          Previous
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default BrowseSub;
