import React, { useState } from "react";
import "./homecontent.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";

import ScrollTrigger from "react-scroll-trigger";
import {
  faPlay,
  faArrowRight,
  faCheck,
  faMagnifyingGlass,
  faClipboardList,
  faLocationDot,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Homecontent = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  const navigate = useNavigate();
  const [Counteron, SetcounterOn] = useState(false);
  return (
    <div className="homecontent">
      <div className="fluid pt-5">
        <div className="container text-center part1">
          <div className="row">
            <div className="col-md-12 section1">
              <h1>
                One Step Closer To
                <span id="nextline">Your Dream Job</span>
              </h1>
              <p>Let Us Help You Find A Job That Suits You The Best!</p>
              <div className="btns mt-5 ">
                <button className="getbtn">Get Started</button>
                <div className="play">
                  <i className="plybtn ">
                    <FontAwesomeIcon icon={faPlay} />
                  </i>
                </div>
                <div className="para">
                  <p className="ourstorybtn">Our Story</p>
                </div>
              </div>
              <img
                className=" arrowleft col-"
                src="./image/arrowleft.png"
                alt="arrow"
              />
              <img
                className=" arrowright"
                src="./image/arrowright (2).png"
                alt="arrow"
              />
              <img
                className=" boxleft"
                src="./images/bannerpicleft.png"
                alt="arrow"
              />
              <img
                className=" boxright"
                src="./images/bannerpicright.png"
                alt="arrow"
              />
              <div className="number mt-5">
                <div className="me-4">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={25} duration={2} delay={0} />
                      )}
                      M+
                    </span>
                  </ScrollTrigger>
                  <span className="name">Users</span>
                </div>
                <div className=" me-4">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={500} duration={2} delay={0} />
                      )}
                      K+
                    </span>
                  </ScrollTrigger>
                  <span className="name">Jobs</span>
                </div>
                <div className="">
                  <ScrollTrigger
                    onEnter={() => SetcounterOn(true)}
                    onExit={() => SetcounterOn(false)}
                  >
                    <span className="num">
                      {Counteron && (
                        <CountUp start={0} end={100} duration={2} delay={0} />
                      )}
                      +
                    </span>
                  </ScrollTrigger>
                  <span className="name">Partners</span>
                </div>
              </div>
            </div>
          </div>
          <p className="bannerlast">Work with exciting companies</p>
        </div>
      </div>
      <div className="owlback">
        <div className="container  part2 text-center ">
          <div className="row">
            <div className="col-md-12 owlbox">
              <OwlCarousel
                className="owl-theme"
                loop
                autoplay={true}
                items="2"
                dots={true}
                smartSpeed={2000}
                nav={false}
                margin={20}
                center={true}
                autoplayTimeout={5000}
                responsive={{
                  360: {
                    items: "1",
                  },
                  414: {
                    items: "2",
                  },
                  670: {
                    items: "3",
                  },
                  992: {
                    items: "4",
                  },
                  1200: {
                    items: "4",
                  },
                }}
              >
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* After carosel */}
      <div className="container text-center mt-5 part3">
        <div className="row">
          <div className="col-md-12 part3cont">
            <h2>
              More Easier To
              <span className="ms-2">
                Find A Job <br />
                With Our Platform
              </span>
            </h2>
            <p className="mt-4">
              Imagine having a Neuralink-like connection to our platform, where
              it understands your unique talents and aspirations. We provide
              personalized job recommendations that align perfectly with your
              skills and ambitions, empowering you to reach for the stars in
              your career.
            </p>
          </div>
          <div className="row pt-5 pb-5  gx-0">
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src="./images/box1.jpeg" alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Easy Applying</h4>
                  <p>The process fo applying for job is easy and fast</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src="./images/box2.jpg" alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Many Vacancies</h4>
                  <p>There are many job vacancies from various company</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="cardimg">
                <img src="./images/box3.jpeg" alt="box1" />
                <div className="cardbody3 mt-5">
                  <h4>Best Support</h4>
                  <p>
                    We provide full support for job seeker to achieve better
                    result
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*OUR SERVICE  */}
      <div>
        <div className="service">
          <div className="container ">
            <div className="row pb-4">
              <div className="col-lg-6 ourservice1">
                <h3 className="mt-4">
                  Our <span>Services</span>
                </h3>
                <p className="mt-4 ourservicepara">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever sinces the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p>
                <button className="morebtn px-4 py-2">
                  More Details{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                </button>
              </div>
              <div className="col-lg-6 ourserviceleft">
                <h5 className="mt-4 me-5">
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                  Recruiting/Staffing Services - Temporary/Permanent
                </h5>
                <p className="mt-3">
                  Lorem Ipsum is simply dummy text of the an and typesetting
                  industry. Lorem Ipsum has been the industry's standard
                </p>
                <h5 className="mt-4">
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                  Industries - Renewable Energy and General Sales Roles{" "}
                </h5>
                <p className="mt-3">
                  Lorem Ipsum is simply dummy text of the ho printing and
                  typesetting industry. Lorem the Ipsum has been the industry's
                  standard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LOOKING FOR A JOB */}
      <div className="container text-center mt-5 lookingmain">
        <div className="row">
          <div className="col-md-12 mt-5 looking">
            <h2>
              Looking for A<br />
              <span id="head"> job now</span>
            </h2>
            <p className="mt-4">
              Type in the name of the position, company or job category you are
              looking for
            </p>
          </div>
          {/*  */}
          <div className="container">
            <div className="row justify-content-center gx-0 searchblog">
              <div className="col-lg-8 col-12 col-md-12">
                <div className="row d-flex  searchrowhme">
                  <div className="col-lg-5  col-12 brdrght col-md-7">
                    <div className="jobcat">
                      <div className="">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="jobcategoryicon"
                        />
                      </div>
                      <div className="inp1">
                        <input
                          type="text"
                          placeholder="Category"
                          // style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-12 col-md-7 ">
                    <div className="jobloc">
                      <div className="d-flex searchrgt">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="joblocicon"
                        />

                        <div className="inp2">
                          <input type="text" placeholder="Location" />
                        </div>
                      </div>

                      <div className="jobsearch ">
                        <button className="jobsrcbtn">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="jobsicon"
                          />
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <p className="popularcat mt-5">Popular Category</p>
          {/*  */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 mb-3">
                <div className="row justify-content-center gx-0">
                  <div className="col-lg-2 col-md-6 col-6 paddrght">
                    <div className="con1 ">
                      <p className="para1">Associate Director</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6" id="left">
                    <div className="con1 ">
                      <p className="para1">BI Analyst </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 mb-3">
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6" id="left">
                    <div className="con1 ">
                      <p className="para1">BI Analyst </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11  mb-3 ">
                <div className="row justify-content-center">
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Officer</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">Installation TA </p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-6 col-6">
                    <div className="con1 ">
                      <p className="para1">BI Analyst</p>
                      <p className="para2">552</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="morecategory mt-4">
              More Categories
              <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {/* avilablebox */}
      <div className="availablejobs">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12 availablehead">
              <h3>
                Choose Our <span className="smallavil">Available Jobs</span>
              </h3>
              <p className=" availpara mt-3">
                Find the following job that suits you and apply now
              </p>
              <button className="recent me-4 mt-4">Recent </button>
              <button className="popular">Popular </button>
            </div>
          </div>
        </div>
        {/* 1*/}
        <div className="container mt-5">
          <div>
            <div className="row">
              <div className="col-md-6 col-lg-4 mt-4">
                <div className="cardavail">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">Associate Director</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>
              {/*  2*/}
              <div className="col-md-6 col-lg-4  mt-4">
                <div className="cardavail">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">Installation TA</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="col-md-6 col-lg-4   mt-4" id="center3">
                <div className="cardavail ">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">Electrical</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>

              {/* 4 */}

              <div className="col-md-6 col-lg-4  mt-4">
                <div className="cardavail">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">BI Analyst</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="col-md-6 col-lg-4  mt-4">
                <div className="cardavail">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">Project Manager</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>
              {/* 6 */}

              <div className="col-md-6  col-lg-4 mt-4" id="center6">
                <div className="cardavail">
                  <div className="smalls ">
                    <span className="fulltime">Fulltime</span>
                    <span className="Onsite ">Onsite</span>
                    <span className="thousands ">$200K</span>
                  </div>
                  <h4 className="cardavailhead">Project Planner</h4>
                  <p className=" cardavailpara">GE Renewable Energy</p>
                  <div className="applydiv">
                    <button className="applybtns">Apply</button>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: "#63E6BE" }}
                      className="employee"
                    />
                    <p className="applyies">24Applied</p>
                  </div>
                </div>
              </div>
            </div>

            <button className="showalljobs mt-4">
              Show All Jobs{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {/* OUR VALUES */}
      <div className="homeourvalues">
        <div className="container ">
          {/* <div className="row"> */}
          <div className="homevalues text-center">
            <h3 className="">
              Our <span>Values</span>
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard
            </p>
          </div>
          {/* ourvalbox 1 */}
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <div className="firstrow">
                <div className="selfabt">
                  <img
                    src="./images/self1.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="firstpara">
                  <h5>Selflessness:</h5>
                  <p>
                    Infletio looks to provide before retrieving.Our model
                    guarantees that 100% ofour client's request is completed
                    before any payment. In addition, Infletio has an
                    everlastingcommitment to giving back to the community with
                    our Non-Profit cut.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-lg-6 mt-5">
              <div className="firstrow mobtop">
                <div className="selfabt">
                  <img
                    src="./images/respect.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="firstpara">
                  <h5>Respect:</h5>
                  <p>
                    Our platform provides full transparency and looks to provide
                    job-seekers andemployers with mutual respect.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt ">
                  <img
                    src="./images/determination.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="secondpara">
                  <h5> Determination:</h5>
                  <p>
                    Your request is our command. We promise to work with you to
                    get you thestaffing you’re looking for, before any deadline
                    agreed upon. Our team will work limitless hoursto get you
                    the result you’re seeking.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt">
                  <img
                    src="./images/family.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="secondpara">
                  <h5 className="ms">Family:</h5>
                  <p>
                    The team at Infletio treats all our clientele relationships
                    as family. After the staffingcycle is over, we maintain that
                    relationship and look to support our clients for life. From
                    letters togifts, the Infletio family goes above and beyond.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* ABOUT US */}
      <div className="container mt-5 aboutcon">
        <div className="row">
          <div className="col-lg-6 aboutus  aboutushme mt-5 ">
            <img
              src="./images/about-us.jpeg"
              className=" image-fluid aboutimg "
              alt="abiut"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-lg-6 aboutright aboutrighthme mt-5">
            <h3>
              About <span className="abouthead">Us</span>
            </h3>
            <p className="aboutpara mt-4">
              In today's job market, there's a lack of support from the staffing
              industry for companies based inthe Renewable Energy space.
            </p>
            <p className="aboutpara mt-3">
              At Infletio Consulting, we understand the unique demands of the
              dynamic and growingrenewable energy sector. As a dedicated
              staffing partner, we offer comprehensive solutionstailored to meet
              the workforce needs of companies involved in renewable energy
              projects aswell as any sales org.
            </p>
            <button
              className="aboutbtn mt-3 "
              onClick={() => navigate("/Aboutus")}
            >
              About Us <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {/* news that help */}
      <div className="newshelp">
        <div className="container text-center ">
          <div className="row">
            <div className="col-lg-12 news mt-5">
              <h3>
                News That <span className="newshead">Helps</span>
              </h3>
              <p className="mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                <br />
                industry. Lorem Ipsum has been the industry's standard
              </p>
            </div>
          </div>
        </div>
        {/* box */}
        <div className="container">
          <div className="newsbox mt-5">
            <div className="row ">
              <div className="col-lg-4 col-md-4">
                <div class="card">
                  <img
                    src="./images/newshelp1.png"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p class="card-text mt-3">
                      How to win any job you want. Get started with 5 steps.
                    </p>
                    <a className="mt-2 read" href="">
                      Read More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4" id="newsboxtop2">
                <div class="card">
                  <img
                    src="./images/newshelp2.png"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p class="card-text mt-3 ">
                      10 ways to reduce your office work depression.
                    </p>
                    <a className="mt-2 read" href="">
                      Read More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4" id="newsboxtop3">
                <div class="card">
                  <img
                    src="./images/newshelp3.png"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <span>Lifestyle</span>
                    </h5>
                    <p class="card-text mt-3">
                      Why should you work as a team even on small projects.
                    </p>
                    <a className="mt-2 read" href="">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="newsbtn pb-5">
            <button className="newshelpbtn">
              View More <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {/* lets start */}
      <div className="container text-center mt-5 let-start">
        <div className="row">
          <div className="col-lg-12 mt-5 letstart">
            <h3>
              Let's Start
              <span className="letstarthead"> Joining Us And Get A Job</span>
            </h3>
            <p className="mt-4">
              Let's join our platform at jobs me for an experience in finding a
              job that is
              <br /> easier and matches what you are looking for
            </p>
            <div className="subscribe mt-5">
              <div className="subscribes">
                <input type="text" placeholder="Subscribe to our newsletter" />
                <button>Join Now</button>
              </div>
            </div>

            <span>
              <img
                src="./image/arrowleft.png"
                className="img-fluid imgarrow1"
                alt="arrow"
              />
              <img
                src="./image/arrowright (2).png"
                className="img-fluid imgarrow2"
                alt="arrow"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homecontent;
