import React from "react";
import "./BrowseCompanies.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  faClipboardList,
  faLocationDot,
  faMagnifyingGlass,
  faArrowRight,
  faSquarePollHorizontal,
  faPenClip,
  faBoxesStacked,
  faGlobe,
  faBusinessTime,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../Navbar";
import Footer from "../../Footer";

const BrowseCompanies = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="jobbanner text-center pt-5 pb-5">
        <h1>
          Find Your <span>Dream Job</span>
        </h1>
        <p className="mt-4">
          Find your next career at companies like HubSpot, Nike, and Dropbox
        </p>
        {/*  */}
        <div className="mt-4 jobsearch ">
          <div className="container">
            <div className="row justify-content-center gx-0 searchblog">
              <div className="col-lg-8 col-12 col-md-12">
                <div className="row d-flex searchrow ">
                  <div className="col-lg-5  col-12 brdrght col-md-7">
                    <div className="jobcat">
                      <div className="">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="jobcategoryicon"
                        />
                      </div>
                      <div className="inp1">
                        <input
                          type="text"
                          placeholder="Category"
                          // style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-12 col-md-7 ">
                    <div className="jobloc">
                      <div className="d-flex searchrgt">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="joblocicon"
                        />

                        <div className="inp2">
                          <input type="text" placeholder="Location" />
                        </div>
                      </div>

                      <div className="jobsearch ">
                        <button className="jobsrcbtn">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="jobsicon"
                          />
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="pagesec mt-4">
        <div className="findjobs">
          <a href="#JobOpportunities">Find Jobs</a>
        </div>
        <div className="BrowseComp ms-5">
          <a href="#BrowseCompanies">Browse Companies</a>
        </div>
      </div>
      {/*  */}
      <div className="reccompanies mt-5 ">
        <div className="container">
          <div className="rechead">
            <h4>Recommended Companies</h4>
            <p>
              Based on your profile, company preferences, and recent activity
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4  mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Nomad</h5>
                <p className="mt-3">
                  Nomad is located in Paris, France. Nomad has generates
                  $728,000 in sales (USD).
                </p>
                <p2 className="business">Business Service</p2>
              </div>
            </div>
            <div className="col-lg-4  mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Discord</h5>
                <p className="mt-3">
                  We'd love to work with someone like you. We care about
                  creating a delightful experience.
                </p>
                <p2 className="business">Business Service</p2>
              </div>
            </div>
            <div className="col-lg-4  mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Maze</h5>
                <p className="mt-3">
                  We're a passionate bunch working from all over the world to
                  build the future of rapid testing together.
                </p>
                <p2 className="business">Business Service</p2>
              </div>
            </div>
            {/* secondrow */}
            <div className="col-lg-4 mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Udacity</h5>
                <p className="mt-3">
                  Udacity is a new type of online university that teaches the
                  actual programming skills.
                </p>
                <p2 className="business">Business Service</p2>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Webflow</h5>
                <p className="mt-3">
                  Webflow is the first design and hosting platform built from
                  the ground up for the mobile age.
                </p>
                <div>
                  <p2 className="business">Business Service</p2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="reccombox1">
                <div className=" recboxtop">
                  <img src="./images/alljoblog1.png" alt="..." />
                  <p>3 jobs</p>
                </div>
                <h5 className="mt-3">Foundation</h5>
                <p className="mt-3">
                  Foundation helps creators mint and auction their digital
                  artworks as NFTs on the Ethereum blockchain.
                </p>
                <p2 className="business">Business Service</p2>
                <p3 className="tech ms-3">Crypto</p3>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="compbycat">
            <div className="container mt-5">
              <div className="compbycathead">
                <h4>Companies by Category</h4>
                <a href="..">
                  <FontAwesomeIcon icon={faArrowRight} className="scrollicon" />
                </a>
              </div>

              <div className="scroll mt-4">
                <div className="scrollowlback">
                  <div className="container  part2 text-center ">
                    <div className="row">
                      <div className="col-md-12 owlbox">
                        <OwlCarousel
                          className="owl-theme"
                          loop
                          autoplay={true}
                          items="2"
                          dots={true}
                          smartSpeed={2000}
                          nav={false}
                          margin={20}
                          center={true}
                          autoplayTimeout={5000}
                          responsive={{
                            360: {
                              items: "1",
                            },
                            414: {
                              items: "2",
                            },
                            670: {
                              items: "3",
                            },
                            992: {
                              items: "4",
                            },
                            1200: {
                              items: "5",
                            },
                          }}
                        >
                          <div class="scrollitem ">
                            <div>
                              <a href="..">
                                <FontAwesomeIcon
                                  icon={faPenClip}
                                  className="owlscrollicon mt-1 mt-1"
                                />
                              </a>
                              <p className="mt-2">Design</p>
                            </div>
                          </div>
                          <div class="scrollitem ">
                            <a href="..">
                              <FontAwesomeIcon
                                icon={faBoxesStacked}
                                className="owlscrollicon mt-1"
                              />
                            </a>
                            <p className="mt-2">Fintech</p>
                          </div>
                          <div class="scrollitem ">
                            <a href="..">
                              <FontAwesomeIcon
                                icon={faGlobe}
                                className="owlscrollicon mt-1"
                              />
                            </a>
                            <p className="mt-2">Hosting</p>
                          </div>
                          <div class="scrollitem ">
                            <a href="..">
                              <FontAwesomeIcon
                                icon={faBusinessTime}
                                className="owlscrollicon mt-1"
                              />
                            </a>
                            <p className="mt-2">Business Service</p>
                          </div>
                          <div class="scrollitem ">
                            <a href="..">
                              <FontAwesomeIcon
                                icon={faDatabase}
                                className="owlscrollicon mt-1"
                              />
                            </a>
                            <p className="mt-2">Developer</p>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="container mt-5">
          <div className="d-flex">
            <a href="..">
              <FontAwesomeIcon
                icon={faSquarePollHorizontal}
                className="resulticon"
                style={{ color: "#12c0ab" }}
              />
            </a>
            <h5 className="results ms-3">24 Results</h5>
          </div>
          {/*  */}
          <div className="resultbox24">
            <div className="row ">
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp1.png" alt="..." />
                  <h6 className="mt-3">Pentagram</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp2.png" alt="..." />
                  <h6 className="mt-3">Wolff Olins</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp3.png" alt="..." />
                  <h6 className="mt-3">Clay</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp4.png" alt="..." />
                  <h6 className="mt-3">MediaMonks</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp5.png" alt="..." />
                  <h6 className="mt-3">Packer</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp6.png" alt="..." />
                  <h6 className="mt-3">Square</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp7.png" alt="..." />
                  <h6 className="mt-3">Divy</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 text-center mt-4">
                <div className="resulbox">
                  <img src="./images/resultjp8.png" alt="..." />
                  <h6 className="mt-3">WebFlow</h6>
                  <p className="mt-3">
                    <span>3 jobs</span>
                  </p>
                </div>
              </div>
              {/* viewmore */}
              <div className="viewmorecomp mt-4">
                <a href="#BrowseSub">View more Design companies</a>{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className=" ms-3 viewmorecompicon"
                  style={{ color: "#12c0ab" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default BrowseCompanies;
