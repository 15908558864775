import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import "./Aboutus.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";

const Aboutus = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="aboutbanner text-center pt-5 pb-5">
        <h1 className="">
          <span>#1 </span>Platform For Jobs
        </h1>
        <p className="mt-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
      {/* owlcarosel */}
      <div className="owlback">
        <div className="container  part2 text-center ">
          <div className="row">
            <div className="col-md-12 owlbox">
              <OwlCarousel
                className="owl-theme"
                loop
                autoplay={true}
                items="2"
                dots={true}
                smartSpeed={2000}
                nav={false}
                margin={20}
                center={true}
                autoplayTimeout={5000}
                responsive={{
                  360: {
                    items: "1",
                  },
                  414: {
                    items: "2",
                  },
                  670: {
                    items: "3",
                  },
                  992: {
                    items: "4",
                  },
                  1200: {
                    items: "4",
                  },
                }}
              >
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
                <div class="item">
                  <img
                    src="./images/log01.png"
                    className="owlimage"
                    alt="logo"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* about us img */}
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src="./images/about-us.jpeg"
                alt=".."
                className="aboutusimg"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-lg-6">
              <div className="aboutright">
                <h3>
                  About <span>Us</span>
                </h3>
                <p className="mt-4">
                  In today's job market, there's a lack of support from the
                  staffing industry for companies based inthe Renewable Energy
                  space.
                </p>
                <p>
                  At Infletio Consulting, we understand the unique demands of
                  the dynamic and growingrenewable energy sector. As a dedicated
                  staffing partner, we offer comprehensive solutionstailored to
                  meet the workforce needs of companies involved in renewable
                  energy projects aswell as any sales org.
                </p>
                <button className="aboutrightbtn mt-3 ">
                  Get In Touch{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OUR VALUES */}
      <div className="homeourvalues">
        <div className="container ">
          {/* <div className="row"> */}
          <div className="homevalues text-center">
            <h3 className="">
              Our <span>Values</span>
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard
            </p>
          </div>
          {/* ourvalbox 1 */}
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <div className="firstrow">
                <div className="selfabt">
                  <img
                    src="./images/self1.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="firstpara">
                  <h5>Selflessness:</h5>
                  <p>
                    Infletio looks to provide before retrieving.Our model
                    guarantees that 100% ofour client's request is completed
                    before any payment. In addition, Infletio has an
                    everlastingcommitment to giving back to the community with
                    our Non-Profit cut.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-lg-6 mt-5">
              <div className="firstrow mobtop">
                <div className="selfabt">
                  <img
                    src="./images/respect.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="firstpara">
                  <h5>Respect:</h5>
                  <p>
                    Our platform provides full transparency and looks to provide
                    job-seekers andemployers with mutual respect.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt ">
                  <img
                    src="./images/determination.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="secondpara">
                  <h5> Determination:</h5>
                  <p>
                    Your request is our command. We promise to work with you to
                    get you thestaffing you’re looking for, before any deadline
                    agreed upon. Our team will work limitless hoursto get you
                    the result you’re seeking.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-lg-6 mt-5">
              <div className="secondrow">
                <div className="selfabt">
                  <img
                    src="./images/family.png"
                    className="selfim"
                    alt="self1"
                  />
                </div>
                <div className="secondpara">
                  <h5 className="ms">Family:</h5>
                  <p>
                    The team at Infletio treats all our clientele relationships
                    as family. After the staffingcycle is over, we maintain that
                    relationship and look to support our clients for life. From
                    letters togifts, the Infletio family goes above and beyond.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* our mission  */}
      <div className="ourmission ">
        <div className="container text-center">
          <div className="ourmisstop">
            <h3>
              Our <span>Mission</span>
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum <br />
              has been the industry's standard dummy text ever since the 1500s
            </p>
          </div>
          {/* box */}
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-5">
                <img
                  src="./images/ourmiss.png"
                  alt=".."
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className="ourmissright">
                <div className="ourmiss">
                  <div className="circle">
                    <span className="text">1</span>
                  </div>
                  <div className="ourmisspara">
                    <h5>Lorem Ipsum</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className="ourmiss mt-5">
                  <div className="circle">
                    <span className="text">2</span>
                  </div>
                  <div className="ourmisspara">
                    <h5>Lorem Ipsum</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className="ourmiss mt-5">
                  <div className="circle">
                    <span className="text">3</span>
                  </div>
                  <div className="ourmisspara">
                    <h5>Lorem Ipsum</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ourvision */}
      <div className="ourvision mt-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="ourvisright">
                {/* <span className="ourvistop"> */}
                <h3>
                  Our <span>vision</span>
                </h3>
                <p className="mt-4 ourvispara">
                  Lorem Ipsum is simply dummy text of the printing and
                  <br />
                  typesetting industry. Lorem Ipsum has been the
                  <br /> industry's standard dummy text ever since the 1500s
                </p>
                {/* </span> */}
                <div className="ourvislefttimg">
                  <img
                    src="./images/ourvisleft.png"
                    alt="..."
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ourvisrightimg">
                <img
                  src="./images/ourvisright.png"
                  alt="..."
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="ourvisdown d-flex">
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    <h4>Lorem Ipsum</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    <h4>Lorem Ipsum</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" ourvisdowm1 d-flex">
                  <span>
                    <FontAwesomeIcon icon={faStar} className="fontstart" />
                  </span>
                  <div className="lorem ms-3">
                    <h4>Lorem Ipsum</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* lets start */}
      <div className="container text-center mt-5 let-start">
        <div className="row">
          <div className="col-lg-12 mt-5 letstart">
            <h3>
              Let's Start
              <span className="letstarthead"> Joining Us And Get A Job</span>
            </h3>
            <p className="mt-4">
              Let's join our platform at jobs me for an experience in finding a
              job that is
              <br /> easier and matches what you are looking for
            </p>
            <div className="subscribe mt-5">
              <div className="subscribes">
                <input type="text" placeholder="Subscribe to our newsletter" />
                <button>Join Now</button>
              </div>
            </div>

            <span>
              <img
                src="./image/arrowleft.png"
                className="img-fluid imgarrow1"
                alt="arrow"
              />
              <img
                src="./image/arrowright (2).png"
                className="img-fluid imgarrow2"
                alt="arrow"
              />
            </span>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Aboutus;
