import React from "react";
import "./Blogs.css";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Blogs = () => {
  return (
    <div>
      <span>
        <Navbar />
      </span>
      <div className="Blogbanner text-center pt-5 pb-5">
        <h1>
          News That <span>Helps</span>
        </h1>
        <p className="mt-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
      <div className="blogtabs">
        <a href="#..." className="ms-5 tablink">
          All
        </a>
        <a href="#..." className="ms-0 tablink">
          Company News
        </a>
        <a href="#..." className="ms-0 tablink">
          Product & Innovation
        </a>
        <a href="#..." className="ms-0 tablink">
          People & Culture
        </a>
        <a href="#..." className="ms-0 tablink">
          Social Impact
        </a>
        <a href="#..." className="ms-0 tablink">
          {" "}
          Research & Reports
        </a>
      </div>
      {/* RECENTLY POST */}
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h5 className="mt-5 blogtophead">
              Recently <span>Posted</span>
            </h5>
            <div className="recentlypost mt-3">
              <div className="d-flex recentfrstflex">
                <img
                  src="./image/recentpost.png"
                  alt="...."
                  className="recentpostpng"
                />
                <div className="recpostcont">
                  <p className="scrollcar ms-4">
                    <span>Career</span>
                  </p>
                  <h4 className="ms-4 mt-3">
                    How to win any job you want. Get started with 5 steps.
                  </h4>
                  <div className="d-flex align-items-center allpostbot ms-4 mt-3">
                    <img src="./image/jesicakoli.png" alt="..." />
                    <p className="ms-1">Jesica Koli</p>
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className="calenicon ms-2"
                      style={{ color: "rgba(85, 85, 85, 1)" }}
                    />
                    <p className="ms-1">02 December 2002</p>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="clockiconrecent ms-2"
                      style={{ color: "rgba(85, 85, 85, 1)" }}
                    />
                    <p className="ms-1">3 Min.To Read</p>
                  </div>
                  <p className="ms-4 mt-3 pe-5 recentcontpara">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            {/* ALL BLOGS */}
            <div>
              <h5 className="mt-5 blogtophead">
                All <span>Blogs</span>
                <div className="row ">
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div class="card-all-blogs">
                      <img
                        src="./image/allblogscard.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <p class="card-title-allblogs">
                          <span>Career</span>
                        </p>
                        <p class="card-text-allblogs">
                          How to win any job you want. Get started with 5 steps.
                        </p>
                        <a href="." class="allblogread">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </h5>
            </div>
          </div>
          {/*  POPULAR POST*/}
          <div className="col-lg-5 ps-5 popularrght">
            <div className="container">
              <div className="popularpost ">
                <h5 className="mt-5 blogtophead">
                  Popular <span>Post</span>
                  <div className="scrollbar mt-3">
                    <div className="scrollbox">
                      <div className=" d-flex">
                        <img src="./image/scrolljpg.png" alt="" />
                        <div className="scrollboxcon">
                          <p className="scrollcar ms-4">
                            <span>Career</span>
                          </p>
                          <p className="mt-2 ms-4">
                            How to win any job you want.Get started with 5
                          </p>
                          <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                            <img src="./image/jesicakoli.png" alt="..." />
                            <p className="ms-2">Jesica Koli</p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockicon ms-3"
                            />
                            <p className="ms-2">3 Min.To Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="scrollbox mt-5">
                      <div className=" d-flex">
                        <img src="./image/scrolljpg.png" alt="" />
                        <div className="scrollboxcon">
                          <p className="scrollcar ms-4">
                            <span>Career</span>
                          </p>
                          <p className="mt-2 ms-4">
                            How to win any job you want.Get started with 5
                          </p>
                          <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                            <img src="./image/jesicakoli.png" alt="..." />
                            <p className="ms-2">Jesica Koli</p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockicon ms-3"
                            />
                            <p className="ms-2">3 Min.To Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="scrollbox mt-5">
                      <div className=" d-flex">
                        <img src="./image/scrolljpg.png" alt="" />
                        <div className="scrollboxcon">
                          <p className="scrollcar ms-4">
                            <span>Career</span>
                          </p>
                          <p className="mt-2 ms-4">
                            How to win any job you want.Get started with 5
                          </p>
                          <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                            <img src="./image/jesicakoli.png" alt="..." />
                            <p className="ms-2">Jesica Koli</p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockicon ms-3"
                            />
                            <p className="ms-2">3 Min.To Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="scrollbox mt-5">
                      <div className=" d-flex">
                        <img src="./image/scrolljpg.png" alt="" />
                        <div className="scrollboxcon">
                          <p className="scrollcar ms-4">
                            <span>Career</span>
                          </p>
                          <p className="mt-2 ms-4">
                            How to win any job you want.Get started with 5
                          </p>
                          <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                            <img src="./image/jesicakoli.png" alt="..." />
                            <p className="ms-2">Jesica Koli</p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockicon ms-3"
                            />
                            <p className="ms-2">3 Min.To Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="scrollbox mt-5">
                      <div className=" d-flex">
                        <img src="./image/scrolljpg.png" alt="" />
                        <div className="scrollboxcon">
                          <p className="scrollcar ms-4">
                            <span>Career</span>
                          </p>
                          <p className="mt-2 ms-4">
                            How to win any job you want.Get started with 5
                          </p>
                          <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                            <img src="./image/jesicakoli.png" alt="..." />
                            <p className="ms-2">Jesica Koli</p>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="clockicon ms-3"
                            />
                            <p className="ms-2">3 Min.To Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
            </div>
            {/* Company NEWS */}
            <div className="container">
              <div className="companynews">
                <h5 className="mt-5 blogtophead">
                  Company<span>News</span>
                </h5>
                <div className="mt-4">
                  <div className="scrollbox">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="scrollbox mt-3">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* PRODUCT & INNOVATION */}
            <div className="container">
              <div className="product-innovation">
                <h5 className="mt-5 blogtophead">
                  Product & <span>Innovation</span>
                </h5>
                <div className="mt-4">
                  <div className="scrollbox">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="scrollbox mt-3">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container"></div>
            {/* People nd Culture */}
            <div className="container">
              <div className="product-innovation">
                <h5 className="mt-5 blogtophead">
                  People & <span>Culture</span>
                </h5>
                <div className="mt-4">
                  <div className="scrollbox">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="scrollbox mt-3">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="scrollbox mt-3">
                    <div className=" d-flex">
                      <img src="./image/scrolljpg.png" alt="" />
                      <div className="scrollboxcon">
                        <p className="scrollcar ms-4">
                          <span>Career</span>
                        </p>
                        <p className="mt-2 ms-4">
                          How to win any job you want.Get started with 5
                        </p>
                        <div className="d-flex align-items-center scrollboxbot ms-4 mt-3">
                          <img src="./image/jesicakoli.png" alt="..." />
                          <p className="ms-2">Jesica Koli</p>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="clockicon ms-3"
                          />
                          <p className="ms-2">3 Min.To Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div>
        <nav aria-label="Page navigation example ">
          <ul class="pagination justify-content-center mt-5">
            <li class="page-item">
              <a class="page-link" href="#">
                Previous
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/*  */}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Blogs;
