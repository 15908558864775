import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Home from "./Components/Pages/Home";
import Aboutus from "./Components/Pages/Aboutus";
import JobOpportunities from "./Components/Pages/JobOpportunities";
import CantactUs from "./Components/Pages/CantactUs";
import BrowseCompanies from "./Components/Pages/BrowseCompanies";
import BrowseSub from "./Components/Pages/BrowseSub";
import Blogs from "./Components/Pages/Blogs";

const Main = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/jobopportunities" element={<JobOpportunities />} />
        <Route path="/cantactus" element={<CantactUs />} />
        <Route path="/BrowseCompanies" element={<BrowseCompanies />} />
        <Route path="/BrowseSub" element={<BrowseSub />} />
        <Route path="/Blogs" element={<Blogs />} />
      </Routes>
    </HashRouter>
  );
};

export default Main;
